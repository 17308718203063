import Joi from "joi";
import authMessages from "../config/text";
import { buildUsefulJoiErrorObject } from "./tools";
import { AccountData, KeyValueObject } from "../types/shared";
import uiTexts from "../config/text";

/**
 * Authentication data validation and verification include all
 * checking before resuming the rest of process like save to DB 
 */
class ProfilValidator {

  private static getErrorsOfSchema(dataSchema: Joi.ObjectSchema<any>, data: any) {
    const { error: joiErrors } = dataSchema.validate(data);

    if (joiErrors !== undefined) {
      return buildUsefulJoiErrorObject(joiErrors.details);
    } else {
      return false;
    }
  }

  /**
   * account profil data validation
   * @param data account profil details
   */
  static profilData(data: AccountData | null) {
    const dataSchema = Joi.object().keys({
      email: Joi.string().email({ tlds: { allow: false } }).required().label(authMessages.signup.messages.errors.email),
      income: Joi.number().min(0).required().label(authMessages.signup.messages.errors.income),
      fname: Joi.string().min(2).max(50).required().label(authMessages.signup.messages.errors.fname),
      lname: Joi.string().min(2).max(50).required().label(authMessages.signup.messages.errors.lname),
      city: Joi.string().min(2).max(50).allow("", null).required().label(authMessages.signup.messages.errors.city),
      postcode: Joi.string().min(2).allow("", null).required().label(authMessages.signup.messages.errors.postcode),
      address: Joi.string().min(3).max(50).allow("", null).required().label(authMessages.signup.messages.errors.address),
      country: Joi.string().min(2).allow("", null).required().label(authMessages.signup.messages.errors.country),
      state: Joi.string().allow("ENABLED", "HOLD", "DISABLED").only().optional().label(authMessages.signup.messages.errors.state),

      libraryIncomePercentile: Joi.number().min(0).max(100).optional().label(authMessages.signup.messages.errors.libraryIncomePercentile),
      youtubeChannelIncomePercentile: Joi.number().min(0).max(100).optional().label(authMessages.signup.messages.errors.youtubeChannelIncomePercentile),
      role: Joi.string().regex(/CUSTOMER|LABEL/).optional().label(authMessages.signup.messages.errors.role),
      memo: Joi.string().allow("", null).optional().label(authMessages.signup.messages.errors.memo),
      address2: Joi.string().optional().allow('', null).label(authMessages.signup.messages.errors.address),
      tax: Joi.number().optional().allow('', null).label(authMessages.signup.messages.errors.noValid),
      timezone: Joi.string().optional().allow('', null).label(authMessages.signup.messages.errors.noValid),
      company: Joi.string().optional().allow('', null).label(authMessages.signup.messages.errors.noValid),
      // new signup details
      owner_type: Joi.string().valid(...uiTexts.signup.form.owner_types).allow("", null).optional().label(authMessages.signup.messages.errors.required),
      owner_name: Joi.string().optional().allow("", null).label(authMessages.signup.messages.errors.required),
      has_music: Joi.string().valid("oui", "non").optional().allow("", null).label(authMessages.signup.messages.errors.required),
      music_in_platforms: Joi.string().optional().allow("", null).label(authMessages.signup.messages.errors.required),
      number_of_releases_to_transfer: Joi.number().min(0).optional().allow("", null).label(authMessages.signup.messages.errors.required),
      existing_platforms: Joi.array().items(Joi.string().valid(...uiTexts.signup.form.platforms)).min(0).optional().allow("", null).label(authMessages.signup.messages.errors.required),
      spotify_url: Joi.string().uri().optional().allow("").allow("", null).label(authMessages.signup.messages.errors.required),
      website_url: Joi.string().uri().optional().allow("").allow("", null).label(authMessages.signup.messages.errors.required),
      extra: Joi.string().optional().allow("").allow("", null).label(authMessages.signup.messages.errors.required),
      social_media_urls: Joi.string().allow("").optional().allow("", null).min(10).label(authMessages.signup.messages.errors.required),
    })
      .options({ abortEarly: false, });
    const { error: joiErrors } = dataSchema.validate(data);

    if (joiErrors !== undefined) {
      return buildUsefulJoiErrorObject(joiErrors.details);
    } else {
      return false;
    }
  }

  static bankAccountData(data: any | null) {
    const messages = uiTexts.account.messages.errors;
    const getStringSchema = (msg: string) => Joi.string().min(2).required().label(msg);
    const dataSchema = Joi.object().keys({
      owner: getStringSchema(messages.owner),
      bankName: getStringSchema(messages.bankName),
      holderCountry: getStringSchema(messages.ownerCountry),
      bankCountry: getStringSchema(messages.bankCountry),
      holderAddress: getStringSchema(messages.holderAddress),
      holderAddress2: Joi.string().allow('').optional().label(messages.holderAddress),
      bankAddress: getStringSchema(messages.bankAddress),
      bankAddress2: Joi.string().allow('').optional().label(messages.bankAddress),
      holderCity: getStringSchema(messages.holderCity),
      bankCity: getStringSchema(messages.bankCity),
      holderPostcode: getStringSchema(messages.holderPostcode),
      bankZipCode: getStringSchema(messages.bankZipCode),
      iban: getStringSchema(messages.iban),
      swift: getStringSchema(messages.swift),
    })
      .options({ abortEarly: false, });

    return this.getErrorsOfSchema(dataSchema, data);
  }

  /**
   * data validation of paypla account ui(required by paypal)
   * @param data contain data to validate
   */
  static paypalAccountData(data: any | null) {
    const messages = uiTexts.account.messages.errors;
    const getStringSchema = (msg: string) => Joi.string().min(2).required().label(msg);
    const dataSchema = Joi.object().keys({
      email: Joi.string().email({ tlds: { allow: false } }).required().label(authMessages.signup.messages.errors.email),
      owner: getStringSchema(messages.owner),
      holderAddress: getStringSchema(messages.holderAddress),
      holderAddress2: Joi.string().allow('').optional().label(messages.holderAddress),
      holderCity: getStringSchema(messages.holderCity),
      holderCountry: getStringSchema(messages.ownerCountry),
      holderPostcode: getStringSchema(messages.holderPostcode),
    })
      .options({ abortEarly: false, });
    return this.getErrorsOfSchema(dataSchema, data);
  }

  /**
   * validate data of payout request
   * @param data required data for payout request
   */
  static payoutData(data: any | null) {
    const messages = uiTexts.account.messages.errors;
    const dataSchema = Joi.object().keys({
      amount: Joi.number().min(0.1).required().label(messages.amount),
      account: Joi.number().required().label(messages.account),
    })
      .options({ abortEarly: false, });
    return this.getErrorsOfSchema(dataSchema, data);
  }

  static payoutStatusData(data: KeyValueObject<string>) {
    const dataSchema = Joi.object().keys({
      status: Joi.string().regex(/PAID|PENDING|CANCEL/).required().label(uiTexts.signup.messages.errors.status),
    })
      .options({ abortEarly: false, });

    return this.getErrorsOfSchema(dataSchema, data);
  }

  /**
   * Data validation of csv releases
   * @param data new release details
   * @returns true if data are valid otherwise list of errors
   */
  static csvReleasesData(data: Record<string, any>) {
    const releaseSchema = Joi.object({
      titre: Joi.string().not().empty().optional(),
      artistes: Joi.string().not().empty().optional(),
      barcode: Joi.string().allow("").optional(),
      isrc: Joi.string().allow("").optional(),
    }).required();
    const dataSchema = Joi.object().keys({
      user: Joi.string().regex(/[0-9]+/).required().label(uiTexts.global.emptyData),
      releases: Joi.array().items(releaseSchema).min(1).required().label(uiTexts.global.inValid)
    })
      .options({ abortEarly: false, });

    return this.getErrorsOfSchema(dataSchema, data);
  }

  /**
   * Validate referrals data
   * @param data referrals details such as percentile, referrer id..
   * @returns true if data are valid otherwise list of errors
   */
  static referralData(data: Record<string, any>) {
    const dataSchema = Joi.object().keys({
      referredId: Joi.number().required().label(uiTexts.global.inValid),
      referrerId: Joi.number().required().label(uiTexts.global.inValid),
      percentile: Joi.number().required().label(uiTexts.global.inValid),
    })
      .options({ abortEarly: false, });

    return this.getErrorsOfSchema(dataSchema, data);
  }

}

export default ProfilValidator;