import Axios from "axios";
import { useEffect, useReducer } from "react";
import { globalReducer } from "../../store/reducer";
import globalLinks from "../../config/links";
import useAbortController from "../use-abort-controller";
import uiTexts from "../../config/text";

const initState: any = {
  loading: true,
  errors: {},
  global: null,
  data: [],
};

/**
 * Retrieve list of accounts
 */
export const useAccounts = (inActive = false) => {
  const { cancelPreviousRequest, newAbortSignal } = useAbortController();
  const [state, dispatch] = useReducer(globalReducer, initState);

  useEffect(() => {
    const loadReportsData = async () => {
      dispatch({
        type: "LOADING",
        payload: true
      });
      try {
        const endpointUrl = inActive === false
          ? `${globalLinks.api.account.allProfiles}`
          : `${globalLinks.api.account.inActivatedProfiles}`;
        cancelPreviousRequest();
        const { data: response } = await Axios.get(endpointUrl, {
          signal: newAbortSignal()
        });

        if (response.code === 'success') {
          dispatch({
            type: 'SET_LIST_WITH_MESSAGE',
            payload: {
              message: null,
              data: response.data?.accounts
            },
          });
        }
      } catch (error) {
        // @ts-ignore
        if (error?.response?.data?.code) {
          dispatch({
            type: 'ERROR',
            // @ts-ignore
            payload: error?.response?.data?.errors,
          });
        } else {
          dispatch({
            type: 'ERROR',
            payload: {
              global:
                uiTexts.global.network
            },
          });
        }
      }
    }
    loadReportsData();
    return () => {
      cancelPreviousRequest();
    }
  }, []);

  return [state, dispatch] as const;
}