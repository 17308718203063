import globalSizes from "../config/sizes";

const mainUrl = '/api/library';
const releasesListByStatus: any = {
  unpaid: `${mainUrl}/unpaid`,
  paid: `${mainUrl}/paid`,
  delivered: `${mainUrl}/delivered`,
};

const globalLinks = {
  signin: '/signin',
  signup: '/signup',
  resetPasswordRequest: '/reset-password',
  resetPasswordAction: '/reset-password-action',
  home: '/',
  tracks: '/tracks',
  releases: '/releases',
  reporting: {
    platform: '/reporting/platform/add',
    youtube: '/reporting/youtube/add',
    all: '/reporting/all',
    list: '/reporting',
    root: '/reporting/:type',
    byTrack: '/reporting/report/:id',
    byPlatform: '/reporting/platform',
    byYoutube: '/reporting/youtube',
  },
  account: {
    root: '/accounts',
    profile: '/accounts/profile',
    profiles: '/accounts/profiles',
    payment: '/account/payment',
    billing: {
      main: '/accounts/billing',
      userBilling: '/accounts/:id/billings',
      bankAccount: '/accounts/billing/bank-account/:id',
      paypal: '/accounts/billing/paypal-account/:id',
    },
    invoices: '/accounts/invoices',
    userInvoices: '/accounts/:id/invoices',
    invoice: '/accounts/invoice/:id',
    inactivate: '/accounts/inactivate',
    payouts: '/payouts/',
    payout: '/payouts/payout/:id',
    pendingPayouts: '/payouts/pending',
    paidPayouts: '/payouts/paid',
    unpayedAccounts: '/payouts/unpaid-accounts',
    manageAdmins: {
      root: "/accounts/manage-admins"
    }
  },
  library: {
    root: '/library',
    releases: '/library/releases/:status',
    unpublishedReleases: '/library/unpublished-releases',
    rootReleases: '/library/releases',
    pendingReleases: '/library/releases/unpaid',
    paidReleases: '/library/releases/paid',
    deliveredReleases: '/library/releases/delivered',
    release: '/library/release',
    editRelease: '/library/release/:id/edit',
    tracks: '/library/tracks',
    artists: '/library/artists',
    contributors: '/library/contributors',
    editors: '/library/editors',
    newRelease: {
      details: '/release/new',
      tracks: '/release/new/:id/tracks',
      plan: '/release/new/:id/plan',
      summary: '/release/new/:id/summary',
      pay: '/release/new/:id/pay',
    }
  },
  services: {
    root: '/services',
    promoCode: {
      list: '/services/promo-code',
      add: '/services/promo-code/add',
    },
    smartlink: {
      list: '/services/smartlink/:status',
      edit: '/services/smartlink/:id',
      item: '/services/smartlink',
    },
    subscriptions: {
      list: '/services/subscriptions',
    },
  },
  accounting: {
    root: '/accounting',
    default: '/accounting/platform',
    platform: '/accounting/platform',
    youtube: '/accounting/youtube',
  },
  constracts: {
    root: '/contracts/create'
  },
  tools: {
    root: '/tools',
    editIsrcOrBarcode: '/tools/edit-isrc-or-barcode',
    isrcRedundancy: '/tools/isrc-redundancy',
    emptyISRCBarcode: '/tools/empty-isrc-barcode',
    namingRedundancy: '/tools/naming-redundancy',
    ticket: {
      listByStatus: '/tools/ticket/:status',
      opened: '/tools/ticket/opened',
      closed: '/tools/ticket/closed',
    }
  },
  api: {
    signup: '/api/auth/signup',
    signin: '/api/auth/signin',
    resetPasswordRequest: '/api/auth/reset_password/request',
    resetPasswordAction: '/api/auth/reset_password/action',
    signout: '/api/auth/signout',
    account: {
      profileRoot: '/api/account/profile',
      allProfiles: '/api/account/profile/all',
      profile: '/api/account',
      bankAccount: '/api/account/new-bank-account',
      updateBilling: '/api/account/payment',
      billingAccounts: '/api/account/billing-accounts',
      billingAccount: '/api/account/billing-account',
      invoices: '/api/account/invoices/all',
      userInvoices: '/api/account/invoices/user',
      invoice: '/api/account/invoice',
      editInvoice: '/api/account/invoices/:id/edit',
      editPayout: '/api/account/payouts',
      editPayoutDownloadState: '/api/account/payouts/download',
      payouts: '/api/account/payouts/unpaid',
      pendingPayouts: '/api/account/payouts/unpaid',
      paidPayouts: '/api/account/payouts/paid',
      payoutRequest: '/api/account/payout/request',
      unpaidAccounts: '/api/account/payouts/unpaid-accounts',
      manageAdmins: {
        initialData: '/api/account/manage-admins/initial-data',
        addAdmin: '/api/account/manage-admins/add',
        item: '/api/account/manage-admins',
      },
      referrals: {
        root: '/api/account/referrals',
        add: '/api/account/referrals/add',
      },
      inActivatedProfiles: '/api/account/profile/inactivated',
    },
    libraries: {
      releasesList: '/api/library/mylist',
      create: '/api/library/create',
      release: '/api/library',
      delivery: '/api/library/delivery',
      unpublished: '/api/library/all/unpublished',
      bulkBarcodeUpdate: '/api/library/edit-barcode',
      resources: '/api/library/resources',
      artists: {
        mylist: '/api/artist/mylist',
        edit: '/api/artist',
        create: '/api/artist/create',
      },
      contributors: {
        mylist: '/api/contributor/mylist',
        edit: '/api/contributor',
      },
      musicians: {
        mylist: '/api/musician/mylist',
        edit: '/api/musician',
      },
      editors: {
        mylist: '/api/editor/mylist',
        edit: '/api/editor',
      },
      tracks: {
        one: '/api/track',
        mylist: '/api/track/mylist',
        delivered: '/api/track/all/delivered',
        edit: '/api/track',
        create: '/api/track/create',
        bulkISRCUpdate: '/api/track/edit-isrc',
        deleteFromDisk: `${globalSizes.resourcesMainDomain}/api/track`
      },
      orders: {
        root: '/api/order',
        clientSecret: '/pay/start',
      },
      releasesListByStatus,
    },
    reporting: {
      root: '/api/report',
      all: '/api/report/all',
      create: '/api/report/create',
      automatic: '/api/report/automatic',
      csv: '/api/report/csv',
      createYoutube: '/api/report/youtube/create',
      delete: '/api/report',
      deleteByMonth: '/api/report/month',
    },
    services: {
      promoCode: {
        list: '/api/services/promo-code/list',
        item: '/api/services/promo-code',
        add: '/api/services/promo-code/create',
      },
      smartlink: {
        list: '/api/services/smartlink/list',
        item: '/api/services/smartlink',
      },
      subscriptions: {
        list: '/api/services/subscription/list',
        item: '/api/services/subscription',
      },
    },
    accountingStats: {
      common: '/api/accounting-stats/common',
      reports: '/api/accounting-stats/reports'
    },
    tools: {
      redundancy: '/api/tools/redundancy',
      namingRedundancy: '/api/tools/naming-redundancy',
      emptyISRCBarcode: '/api/tools/empty-isrc-barcode',
      ticket: {
        root: '/api/tools/ticket',
      }
    }
  },
  conditions: 'https://www.dropzik.com/conditions',
  terms: 'https://www.dropzik.com/terms',
  fenanPro: 'fenan.pro',
  youtubeVideo: 'https://www.youtube.com/watch'
}

export default globalLinks;